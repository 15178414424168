import React, { useState } from "react";
import questions from "./questions";

export default function App() {
  const initialState = {
    showStartScreen: true,
    currentQuestion: 0,
    showScore: false,
    score: 0,
    showFinal: false,
    clicked: -1,
    disabled: false,
  };
  const mapToAlphabeth = {
    0: "a",
    1: "b",
    2: "c",
    3: "d",
  };
  const [currentQuestion, setCurrentQuestion] = useState(
    initialState.currentQuestion
  );
  const [showStartScreen, setShowStartScreen] = useState(
    initialState.showStartScreen
  );
  const [showScore, setShowScore] = useState(initialState.showScore);
  const [score, setScore] = useState(initialState.score);
  const [showFinal, setShowFinal] = useState(initialState.showFinal);
  const [clicked, setClicked] = useState(initialState.clicked);
  const [disabled, setDisabled] = useState(initialState.disabled);

  const percentage = Math.floor((score / questions.length) * 100);
  const hasPassed = percentage >= 75;

  const resetGame = () => {
    setShowStartScreen(initialState.showStartScreen);
    setShowScore(initialState.showScore);
    setCurrentQuestion(initialState.currentQuestion);
    setScore(initialState.score);
    setShowFinal(initialState.showFinal);
  };
  const handleAnswerOptionClick = (isCorrect) => {
    setDisabled(true);
    if (isCorrect) {
      setScore(score + 1);
    }
    setTimeout(() => {
      setDisabled(initialState.disabled);
      setClicked(initialState.clicked);
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowScore(true);
      }
    }, 1000);
  };

  return (
    <div className="app">
      {showStartScreen ? (
        <>
          <div className="top-section score-section">
            <h1>Lisannes Giraffen Quizzz</h1>
          </div>
          <div className="bottom-section">
            <button className="full" onClick={() => setShowStartScreen(false)}>
              Start!
            </button>
          </div>
        </>
      ) : (
        <>
          {showScore ? (
            <>
              <div className="top-section score-section">
                <p className="score-text">
                  Je hebt {score} van de {questions.length} vragen goed
                  beantwoord.
                </p>
                <h1 className="question-text">
                  {hasPassed
                    ? `Netjes, ${percentage}% goed! 🎉`
                    : `Dat is ${percentage}% en helaas niet goed genoeg. 😭`}
                </h1>
              </div>

              <div className="bottom-section">
                {hasPassed ? (
                  <button
                    className="full"
                    onClick={() => {
                      setShowFinal(true);
                      setShowScore(initialState.showScore);
                    }}
                  >
                    Haal beloning op.
                  </button>
                ) : (
                  <button className="full" onClick={resetGame}>
                    Probeer opnieuw
                  </button>
                )}
              </div>
            </>
          ) : (
            <>
              {showFinal ? (
                <>
                  <div className="top-section">
                    <h1>
                      Gefeliciteerd, je bent geslaagd voor de test!
                      <span aria-label="party" role="img">
                        🎉
                      </span>
                    </h1>
                    <p>Je beloning ligt klaar op je oude slaapkamer.</p>
                  </div>
                  <div className="bottom-section">
                    <button className="full" onClick={resetGame}>
                      Speel nog een keer
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="top-section question-section">
                    <div className="question-count">
                      <span>Vraag {currentQuestion + 1}</span>/
                      {questions.length}
                    </div>
                    <h1>{questions[currentQuestion].questionText}</h1>
                  </div>
                  <div className="bottom-section answer-section">
                    {questions[currentQuestion].answerOptions.map(
                      (answerOption, index) => {
                        const hasClicked = clicked === index;
                        const color = answerOption.isCorrect
                          ? "correct"
                          : "incorrect";
                        const className = [
                          "answer ",
                          hasClicked ? color : null,
                        ].join("");
                        const classNameCount = [
                          "count ",
                          hasClicked ? "clicked" : null,
                        ].join("");
                        return (
                          <button
                            key={index.toString()}
                            onClick={() => {
                              setClicked(index);
                              handleAnswerOptionClick(answerOption.isCorrect);
                            }}
                            className={className}
                            disabled={disabled}
                          >
                            <span className={classNameCount}>
                              {mapToAlphabeth[index]}.
                            </span>
                            <span className="text">
                              {answerOption.answerText}
                            </span>
                            <svg className="icon" viewBox="0 0 192 192">
                              {hasClicked ? (
                                <>
                                  {answerOption.isCorrect ? (
                                    <path d="M162 24l-90 90-42-42-30 30 72 72 120-120z" />
                                  ) : (
                                    <path d="M190.249 154.249c-0.001-0.001-0.001-0.002-0.002-0.002l-58.246-58.247 58.246-58.247c0.001-0.001 0.001-0.001 0.002-0.002 0.627-0.627 1.080-1.36 1.371-2.14 0.796-2.133 0.342-4.628-1.373-6.343l-27.515-27.515c-1.715-1.715-4.209-2.169-6.343-1.373-0.78 0.291-1.513 0.744-2.141 1.371 0 0.001-0.001 0.001-0.001 0.002l-58.246 58.247-58.247-58.247c-0.001-0.001-0.001-0.001-0.002-0.002-0.627-0.627-1.36-1.080-2.14-1.371-2.134-0.796-4.628-0.342-6.343 1.372l-27.515 27.515c-1.715 1.715-2.169 4.21-1.373 6.343 0.291 0.78 0.744 1.514 1.371 2.141 0.001 0 0.001 0.001 0.002 0.002l58.247 58.246-58.247 58.247c-0 0.001-0.001 0.001-0.001 0.002-0.627 0.627-1.080 1.36-1.371 2.14-0.796 2.133-0.342 4.628 1.373 6.343l27.515 27.515c1.716 1.715 4.21 2.169 6.343 1.373 0.78-0.291 1.513-0.744 2.141-1.371 0-0.001 0.001-0.001 0.002-0.002l58.247-58.247 58.247 58.247c0.001 0 0.001 0.001 0.002 0.002 0.628 0.627 1.36 1.080 2.14 1.371 2.133 0.796 4.628 0.342 6.343-1.373l27.515-27.515c1.715-1.715 2.169-4.21 1.373-6.343-0.291-0.78-0.744-1.513-1.371-2.14z"></path>
                                  )}
                                </>
                              ) : null}
                            </svg>
                          </button>
                        );
                      }
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
