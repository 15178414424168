const questions = [
  {
    questionText: "Wat is de wetenschappelijke naam van een giraffe?",
    answerOptions: [
      { answerText: "Cervus elaphus", isCorrect: false },
      { answerText: "Giraffa camelopardalis", isCorrect: true },
      { answerText: "Hippopotamus amphibius", isCorrect: false },
      { answerText: "Equus ferus caballus", isCorrect: false },
    ],
  },
  {
    questionText: "Een jonge giraffe heet een ...",
    answerOptions: [
      { answerText: "Kalf", isCorrect: true },
      { answerText: "Welp", isCorrect: false },
      { answerText: "Veulen", isCorrect: false },
      { answerText: "Lam", isCorrect: false },
    ],
  },
  {
    questionText:
      "De moeder bevalt na een draagtijd van ... maanden van haar jong.",
    answerOptions: [
      { answerText: "4 a 5", isCorrect: false },
      { answerText: "8 a 9", isCorrect: false },
      { answerText: "11 a 12", isCorrect: false },
      { answerText: "14 a 15", isCorrect: true },
    ],
  },
  {
    questionText: "Vrouwtjes bevallen ...",
    answerOptions: [
      { answerText: "liggend", isCorrect: false },
      { answerText: "zittend", isCorrect: false },
      { answerText: "gehurkt", isCorrect: false },
      { answerText: "staand", isCorrect: true },
    ],
  },
  {
    questionText: "Het jong kan staan, binnen ...",
    answerOptions: [
      { answerText: "30 minuten", isCorrect: true },
      { answerText: "2 uur", isCorrect: false },
      { answerText: "12 uur", isCorrect: false },
      { answerText: "24 uur", isCorrect: false },
    ],
  },
  {
    questionText: "Welke kleur is de tong van een giraffe?",
    answerOptions: [
      { answerText: "geel", isCorrect: false },
      { answerText: "wit", isCorrect: false },
      { answerText: "donkerblauw", isCorrect: true },
      { answerText: "donkerrood", isCorrect: false },
    ],
  },
  {
    questionText: "Hebben giraffen tanden?",
    answerOptions: [
      { answerText: "Ja", isCorrect: true },
      { answerText: "Nee", isCorrect: false },
    ],
  },
  {
    questionText: "Waar leven giraffen?",
    answerOptions: [
      { answerText: "Zuid-Amerika", isCorrect: false },
      { answerText: "Afrika", isCorrect: true },
      { answerText: "Azië", isCorrect: false },
      { answerText: "Oceanië", isCorrect: false },
    ],
  },
  {
    questionText: "Wat weegt een volwassen vrouwtje ongeveer?",
    answerOptions: [
      { answerText: "100 - 200kg", isCorrect: false },
      { answerText: "250 - 350kg", isCorrect: false },
      { answerText: "400 - 500kg", isCorrect: true },
      { answerText: "600 - 700kg", isCorrect: false },
    ],
  },
  {
    questionText:
      "Waar dient het donkere plukje haar aan het einde van de staart voor?",
    answerOptions: [
      { answerText: "Bescherming tegen insecten", isCorrect: true },
      { answerText: "Afleiding bij een aanval", isCorrect: false },
      { answerText: "Wassen van de vacht", isCorrect: false },
      { answerText: "Geen functie", isCorrect: false },
    ],
  },
  {
    questionText:
      "Met hoeveel kilometer per uur kan een giraffe een paar kilometer rennen?",
    answerOptions: [
      { answerText: "20", isCorrect: false },
      { answerText: "30", isCorrect: false },
      { answerText: "40", isCorrect: false },
      { answerText: "50", isCorrect: true },
    ],
  },
  {
    questionText: "Giraffen kunnen goed zwemmen",
    answerOptions: [
      { answerText: "Waar", isCorrect: false },
      { answerText: "Niet waar", isCorrect: true },
    ],
  },
  {
    questionText:
      "Giraffen staan bekend om hun lange nek, om goed bij de bladeren van bomen te komen. Hoe lang is de nek?",
    answerOptions: [
      { answerText: "2 meter", isCorrect: true },
      { answerText: "3 meter", isCorrect: false },
      { answerText: "4 meter", isCorrect: false },
      { answerText: "5 meter", isCorrect: false },
    ],
  },
  {
    questionText:
      "Ook de lange tong helpt mee om het grijpen van voedsel makkelijker te maken. De tong is ongeveer ...",
    answerOptions: [
      { answerText: "30cm", isCorrect: false },
      { answerText: "40cm", isCorrect: false },
      { answerText: "50cm", isCorrect: true },
      { answerText: "60cm", isCorrect: false },
    ],
  },

  {
    questionText: "Hoelang slaapt een giraffe per dag?",
    answerOptions: [
      { answerText: "2 uur", isCorrect: false },
      { answerText: "4 uur", isCorrect: true },
      { answerText: "8 uur", isCorrect: false },
      { answerText: "12 uur", isCorrect: false },
    ],
  },
  // {
  //   questionText: "",
  //   answerOptions: [
  //     { answerText: "", isCorrect: true },
  //     { answerText: "", isCorrect: false },
  //     { answerText: "", isCorrect: false },
  //     { answerText: "", isCorrect: false },
  //   ],
  // },
];

export default questions;
